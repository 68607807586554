<template>
    <div class="package-detail-container">
        <div class="package-detail-container-top">
            <van-nav-bar title="套餐包详情" fixed/>
        </div>
        <div class="package-detail-container-content">
            <van-cell-group>
                <van-cell title="套餐包名称" :value="order.master.title"/>
                <van-cell title="创建时间" :value="order.master.createAt"/>
                <van-cell title="创建人" :value="order.master.createBy"/>
                <van-cell title="修改时间" :value="order.master.modifyAt||'未修改'"/>
                <van-cell title="修改人" :value="order.master.modifyBy||'未修改'"/>
                <van-cell title="兑换开始日期" :value="order.master.start"/>
                <van-cell title="兑换结束日期" :value="order.master.end"/>
                <van-cell title="兑换码前缀" :value="order.master.prefix"/>
                <van-cell title="兑换码长度" :value="order.master.length + '位'"/>
                <van-cell title="兑换码数量" :value="order.master.num + '份'"/>
                <van-cell title="已生成数量" :value="order.master.generate + '份'"/>
                <van-cell title="已兑换数量" :value="order.master.exchange + '份'"/>
                <van-cell title="套餐包图片" :label="order.master.banner"/>
                <div class="van-cell">
                    <div class="van-cell__title">
                        <span>套餐包说明</span>
                        <div class="van-cell__label" v-html="order.master.detail"/>
                    </div>
                </div>
                <van-cell title="套餐明细" v-if="order.goods.length > 0" is-link :arrow-direction="showGoods?'down':''"
                          @click="showGoods=!showGoods"/>
                <van-swipe-cell v-for="(item ,index) in order.goods" v-bind:key="index" v-show="showGoods">
                    <van-cell :border="true" :title="item.title + '（已兑' + item.exchange + '份）'" :label="item.info"/>
                    <template slot="right">
                        <van-button square type="primary" text="明细" @click="showConfig(index)"/>
                    </template>
                </van-swipe-cell>

            </van-cell-group>
        </div>
        <!-- 弹出区域 -->
        <div class="package-detail-container-popup">
            <!-- 套餐明细 -->
            <van-popup v-model="picker.config">
                <van-nav-bar title="套餐明细" @click-right="picker.config=false">
                    <van-icon name="cross" slot="right"/>
                </van-nav-bar>
                <van-cell-group style="width: 300px">
                    <div v-for="(item ,index) in configList" v-bind:key="index">
                        <van-cell class="package-config" :border="true" :title="item.title+'（卡批次：'+item.card_id +'）'"
                                  :value="'x' + item.num"
                                  :label="item.info"/>
                    </div>
                </van-cell-group>
            </van-popup>
        </div>
    </div>
</template>

<script>


    export default {
        name: 'customer-package-detail',
        components: {},
        props: ['node', 'param'],
        data() {
            return {
                auth: this.$store.state.user.auth || [],
                order: {
                    master: {},
                    goods: []
                },
                picker: {
                    config: false
                },
                configList: [],
                showGoods: true
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            // 初始化
            init() {
                let that = this;
                if (that.param && that.param.id > 0) {
                    that.$api.company.package.detail(that.param.id).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.order = res.data;
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                }
            },
            showConfig(index) {
                this.configList = this.order.goods[index].config;
                this.picker.config = true;
            }
        }
    }
</script>

<style scoped>

    .package-detail-container {
        background-color: #f5f5f5;
        height: 100%;
        width: 100%;
    }

    .package-detail-container-content {
        position: fixed;
        top: 46px;
        bottom: 0;
        overflow-y: scroll;
        left: 0;
        right: 0;
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

    .package-detail-container-content .van-cell__title {
        flex: 3
    }

    .package-detail-container-content .van-cell__value {
        flex: 2
    }

    .package-config .van-cell__title {
        flex: 5
    }


</style>
