<template>
    <div class="customer-search-container">
        <div class="customer-search-container-top">
            <van-nav-bar title="套餐包管理" fixed @click-right="onAdd">
                <van-icon name="plus" slot="right" />
            </van-nav-bar>
        </div>
        <div class="customer-search-container-list">
            <van-pull-refresh v-model="freshing" @refresh="onRefresh">
                <van-list
                        v-model="loading"
                        :finished="finished"
                        :finished-text="finishedText"
                        @load="onLoad"
                >
                    <van-swipe-cell v-for="(item ,index) in list" v-bind:key="index">
                        <template slot="left">
                            <van-button square type="primary" icon="my iconfont icon-chakan" text="查看"
                                        @click="onDetail(item)"/>
                        </template>
                        <van-cell :border="true" :value="item.num + '份'"
                                  :label="'兑换日期：' + item.start + '至' + item.end">
                            <template slot="title">
                                <van-tag round plain type="danger" v-if="item.status == 0">未发布</van-tag>
                                <van-tag round plain type="success" v-if="item.status == 1">已发布</van-tag>
                                <van-tag round plain type="primary" v-if="item.status == 2">部分发</van-tag>
                                <span class="custom-title">{{item.title}}</span>
                            </template>
                        </van-cell>
                        <template slot="right">
                            <van-button square type="primary" icon="edit" text="修改" @click="onEdit(item)"/>
                            <van-button square type="info" icon="my iconfont icon-fabu" text="发布"
                                        @click="onPublish(item)" v-if="item.status != 1"/>
                            <van-button square type="danger" icon="delete" text="删除" @click="onDelete(item)" v-if="item.exchange==0"/>
                        </template>
                    </van-swipe-cell>
                </van-list>
            </van-pull-refresh>
        </div>
        <div class="customer-search-container-pagination" v-show="pages>0">
            <van-pagination
                    v-model="option.page"
                    :page-count="pages"
                    mode="simple"
                    @change="onLoad"
            />
        </div>
        <!-- 弹出区域 -->
        <div class="customer-search-container-popup">
            <!-- 详情 -->
            <van-popup v-model="picker.detail" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <packageDetail :param="data" :key="data.id" v-if="data.id > 0"/>
            </van-popup>
            <!-- 修改 -->
            <van-popup v-model="picker.input" position="right" closeable close-icon="arrow-left"
                       close-icon-position="top-left"
                       :style="{width:'100%',height:'100%'}">
                <inputPackage :param="data" :key="data.id" @onChange="onEditComplete" @onClose="onAddComplete"
                              v-if="data.uid > 0"/>
            </van-popup>
        </div>
    </div>
</template>

<script>
    import inputPackage from './input'
    import packageDetail from './detail'

    export default {
        name: "customer-package-search",
        props: ['node', 'param'],
        components: {
            inputPackage,
            packageDetail,
        },
        data() {
            return {
                auth: this.$store.state.user.auth || [],
                freshing: false,
                list: [],
                statusList: [{index: '', text: '全部'}, {index: 0, text: '未发布'}, {index: 1, text: '已发布'}, {
                    index: 2,
                    text: '部分发布'
                }],
                loading: false,
                finished: true,
                finishedText: '',
                pages: 0,
                option: {
                    page: 1,
                    uid: this.param && this.param.uid ? this.param.uid : 0,
                    title: '',
                    status: '',
                    statusName: '全部',
                },
                picker: {
                    search: false,
                    input: false,
                    detail: false,
                    manage: false,
                    status: false,
                },
                data: {
                    id: 0,
                    uid: this.param && this.param.uid ? this.param.uid : 0,
                }
            }
        },
        mounted: function () {
            this.init();
        },
        methods: {
            init() {
                this.onLoad();
            },
            onSearch() {
                this.option.page = 1;
                this.onLoad();
            },
            onRefresh() {
                this.option.page = 1;
                this.freshing = false;
                this.onLoad();
            },
            onLoad() {
                let that = this;
                that.picker.search = false;
                that.list = [];
                that.loading = true;
                that.finished = false;
                that.$api.company.package.search(that.option).then(response => {
                    that.loading = false;
                    that.finished = true;
                    let res = response.data;
                    if (res.code == 1) {
                        if (res.data.list.length == 0) {
                            that.finishedText = '暂无套餐包';
                        } else {
                            that.finishedText = '';
                        }
                        that.pages = res.data.pages;
                        that.list = res.data.list;
                    } else {
                        that.$toast(res.msg);
                    }
                })
            },
            onAdd() {
                this.data.id = 0;
                this.picker.input = true;
            },
            onAddComplete() {
                this.data.id = 0;
                this.picker.input = false;
                this.$toast('添加成功');
                this.onSearch();
            },
            onDetail(item) {
                this.data.id = item.id;
                this.picker.detail = true;
            },
            onEdit(item) {
                this.data.id = item.id;
                this.picker.input = true;
            },
            onEditComplete() {
                this.data.id = 0;
                this.picker.input = false;
                this.onLoad();
            },
            onDelete(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '操作不可逆，确认删除吗？'
                }).then(() => {
                    that.$api.company.package.delete(item).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                });
            },
            onPublish(item) {
                let that = this;
                that.$dialog.confirm({
                    message: '操作不可逆，确认发布吗？'
                }).then(() => {
                    that.$api.company.package.publish(item).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.$toast.success(res.msg);
                            that.onLoad();
                        } else {
                            that.$toast.fail(res.msg);
                        }
                    });
                });
            }
        }
    }
</script>

<style scoped>
    .customer-search-container {
        padding-top: 46px;
    }

    .customer-search-container-top {
        position: fixed;
        width: 100%;
        height: 46px;
        top: 0;
    }

    .customer-search-container-list {
        position: fixed;
        width: 100%;
        top: 46px;
        bottom: 40px;
        overflow-y: scroll;
    }

    .customer-search-container-pagination {
        position: fixed;
        width: 100%;
        height: 40px;
        bottom: 0;
    }

    .van-cell__title {
        flex: 3
    }

    .van-cell__value{
        flex: 1
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }
</style>
