<template>
    <div class="customer-package-input-container">
        <div class="customer-package-input-container-top">
            <van-nav-bar :title="order.master.id > 0 ? '修改套餐包' : '新增套餐包'" fixed/>
        </div>
        <div class="customer-package-input-container-form">
            <van-cell-group>
                <van-field
                        v-model="order.master.title"
                        required
                        clearable
                        label="套餐包名称"
                        placeholder="请输入套餐包名称"
                />
                <van-field
                        v-model="order.master.start"
                        required
                        type="text"
                        clearable
                        readonly
                        label="开始日期"
                        placeholder="请选择开始日期"
                        @click="picker.start=true"
                />
                <van-field
                        v-model="order.master.end"
                        required
                        type="text"
                        clearable
                        readonly
                        label="结束日期"
                        placeholder="请选择结束日期"
                        @click="picker.end=true"
                />
                <van-field
                        v-model="order.master.num"
                        required
                        type="number"
                        clearable
                        label="兑换码数量"
                        placeholder="请输入兑换码数量"
                        maxlength="5"
                />
                <van-field
                        v-model="order.master.prefix"
                        required
                        type="text"
                        clearable
                        label="兑换码前缀"
                        placeholder="请输入兑换码前缀"
                        maxlength="5"
                />
                <van-field
                        v-model="order.master.lengthName"
                        required
                        readonly
                        type="text"
                        clearable
                        label="兑换码长度"
                        placeholder="请选择兑换码长度"
                        @click="picker.length=true"
                />
                <van-field
                        v-model="order.master.typeName"
                        required
                        type="text"
                        clearable
                        readonly
                        label="兑换码类型"
                        placeholder="请选择兑换码类型"
                        @click="picker.type=true"
                />
                <van-field
                        v-model="order.master.banner"
                        required
                        type="text"
                        clearable
                        label="套餐包图片"
                        placeholder="请输入图片地址"
                        autosize
                />
                <van-field
                        v-model="order.master.detail"
                        required
                        type="textarea"
                        clearable
                        label="套餐包说明"
                        placeholder="请输入套餐包详情"
                        autosize
                />
                <van-field
                        required
                        readonly
                        label="套餐明细"
                        right-icon="plus"
                        @click-right-icon="onAddItem"
                />
                <van-swipe-cell v-for="(item ,index) in order.goods" v-bind:key="index">
                    <van-cell :border="true" :title="item.title" :label="item.info"/>
                    <template slot="right">
                        <van-button square type="primary" text="修改" @click="onEditItem(index)"/>
                        <van-button square type="info" text="配置" @click="onConfigItem(index)"/>
                        <van-button square type="danger" text="删除" @click="onDeleteItem(index)"/>
                    </template>
                </van-swipe-cell>
            </van-cell-group>
        </div>
        <div class="customer-package-input-container-btn">
            <van-button type="info" icon="passed" size="large" @click="onSubmit"
                        style="position:fixed;bottom:0;"
                        text="提交"/>
        </div>
        <div class="customer-package-input-container-popup">
            <!-- 开始日期 -->
            <van-popup v-model="picker.start" position="bottom" :style="{height:'50%'}">
                <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        @cancel="picker.start=false"
                        @confirm="onChangeStartDate"
                />
            </van-popup>
            <!-- 结束日期 -->
            <van-popup v-model="picker.end" position="bottom" :style="{height:'50%'}">
                <van-datetime-picker
                        v-model="currentDate"
                        type="date"
                        @cancel="picker.end=false"
                        @confirm="onChangeEndDate"
                />
            </van-popup>
            <!-- 类型 -->
            <van-popup v-model="picker.type" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="兑换码类型"
                        :columns="typeList"
                        @confirm="onChangeType"
                        @cancel="picker.type=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 长度 -->
            <van-popup v-model="picker.length" position="bottom" :style="{height:'50%'}">
                <van-picker
                        title="兑换码长度"
                        :columns="lengthList"
                        @confirm="onChangeLength"
                        @cancel="picker.length=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 添加套餐 -->
            <van-popup v-model="picker.itemAdd">
                <van-nav-bar title="添加套餐"/>
                <van-cell-group style="width: 300px">
                    <van-field v-model="item.title" required clearable label="套餐名称" placeholder="请输入套餐名称"/>
                    <van-field
                            v-model="item.info"
                            required clearable
                            label="特别说明"
                            type="textarea"
                            placeholder="请输入特别说明"
                            maxlength="50"
                            autosize
                            show-word-limit
                    />
                    <van-field
                            v-model="item.detail"
                            required clearable
                            label="套餐详情"
                            type="textarea"
                            placeholder="请输入套餐详情"
                            autosize
                    />
                    <van-button square type="info" size="large" @click="onAddItemComplete">确定</van-button>
                </van-cell-group>
            </van-popup>
            <!-- 修改套餐 -->
            <van-popup v-model="picker.itemEdit">
                <van-nav-bar title="修改套餐" @click-right="picker.itemEdit=false">
                    <van-icon name="cross" slot="right"/>
                </van-nav-bar>
                <van-cell-group style="width: 300px">
                    <van-field v-model="item.title" required clearable label="套餐名称" placeholder="请输入套餐名称"/>
                    <van-field
                            v-model="item.info"
                            required clearable
                            label="特别说明"
                            type="textarea"
                            placeholder="请输入特别说明"
                            maxlength="50"
                            autosize
                            show-word-limit
                    />
                    <van-field
                            v-model="item.detail"
                            required clearable
                            label="套餐详情"
                            type="textarea"
                            placeholder="请输入套餐详情"
                            autosize
                    />
                    <van-button square type="info" size="large" @click="onEditItemComplete">确定</van-button>
                </van-cell-group>
            </van-popup>
            <!-- 配置套餐 -->
            <van-popup v-model="picker.itemConfig">
                <van-nav-bar title="配置套餐" @click-left="picker.itemConfig=false" @click-right="onAddConfig">
                    <van-icon name="cross" slot="left"/>
                    <van-icon name="plus" slot="right"/>
                </van-nav-bar>
                <van-cell-group style="width: 300px">
                    <div v-if="configList.length == 0">
                        <van-divider>请添加项目</van-divider>
                    </div>
                    <div v-if="configList.length > 0">
                        <van-swipe-cell v-for="(item ,index) in configList" v-bind:key="index">
                            <van-cell :border="true" :title="item.title" :value="'x' + item.num"
                                      :label="'卡批次：'+item.card_id"/>
                            <template slot="right">
                                <van-button square type="primary" text="修改" @click="onEditConfig(index)"/>
                                <van-button square type="danger" text="删除" @click="onDeleteConfig(index)"/>
                            </template>
                        </van-swipe-cell>
                    </div>
                    <van-button square type="info" size="large" @click="onConfigItemComplete">确定</van-button>
                </van-cell-group>
            </van-popup>
            <!-- 添加套餐项目 -->
            <van-popup v-model="picker.configAdd">
                <van-nav-bar
                        title="添加项目"
                        @click-right="picker.configAdd=false">
                    <van-icon name="cross" slot="right"/>
                </van-nav-bar>
                <van-cell-group style="width: 300px">
                    <van-field v-model="config.card_id" type="number" required clearable label="卡批次"
                               placeholder="请输入卡批次"/>
                    <van-field v-model="config.num" type="number" required clearable label="数量"
                               placeholder="请输入数量"/>
                    <van-field v-model="config.title" type="textarea" required clearable label="标题"
                               placeholder="请输入标题" maxlength="50" autosize show-word-limit/>
                    <van-field v-model="config.info" type="textarea" required clearable label="描述"
                               placeholder="请输入描述" maxlength="50" autosize show-word-limit/>
                    <van-button square type="info" size="large" @click="onAddConfigComplete">确定</van-button>
                </van-cell-group>
            </van-popup>
            <!-- 修改套餐项目 -->
            <van-popup v-model="picker.configEdit">
                <van-nav-bar title="修改项目" @click-right="picker.configEdit=false">
                    <van-icon name="cross" slot="right"/>
                </van-nav-bar>
                <van-cell-group style="width: 300px">
                    <van-field v-model="config.card_id" type="number" required clearable label="卡批次"
                               placeholder="请输入卡批次"/>
                    <van-field v-model="config.num" type="number" required clearable label="数量"
                               placeholder="请输入数量"/>
                    <van-field v-model="config.title" type="textarea" required clearable label="标题"
                               placeholder="请输入标题" maxlength="50" autosize show-word-limit/>
                    <van-field v-model="config.info" type="textarea" required clearable label="描述"
                               placeholder="请输入描述" maxlength="50" autosize show-word-limit/>
                    <van-button square type="info" size="large" @click="onEditConfigComplete">确定</van-button>
                </van-cell-group>
            </van-popup>
        </div>
    </div>
</template>

<script>

    export default {
        name: 'customer-package-input',
        components: {},
        props: ['node', 'param'],
        data() {
            return {
                order: {
                    master: {
                        id: 0,
                        uid: this.param && this.param.uid ? this.param.uid : 0,
                        title: '',
                        start: '',
                        end: '',
                        num: '',
                        prefix: 'VIP',
                        length: 8,
                        lengthName: '8位',
                        type: 1,
                        typeName: '仅数字',
                        banner: '',
                        detail: '',
                    },
                    goods: []
                },
                typeList: [
                    {index: 1, text: '仅数字'},
                    {index: 2, text: '仅字母'},
                    {index: 3, text: '混合'}
                ],
                lengthList: [
                    {index: 8, text: '8位'},
                    {index: 9, text: '9位'},
                    {index: 10, text: '10位'},
                    {index: 11, text: '11位'},
                    {index: 12, text: '12位'},
                    {index: 13, text: '13位'},
                    {index: 14, text: '14位'},
                    {index: 15, text: '15位'}
                ],
                item: {
                    id: 0,
                    title: '',
                    info: '',
                    detail: '',
                },
                configList: [],
                config: {
                    id: 0,
                    card_id: '',
                    num: '',
                    title: '',
                },
                picker: {
                    start: false,
                    end: false,
                    type: false,
                    length: false,
                    itemAdd: false,
                    itemEdit: false,
                    itemConfig: false,
                    configAdd: false,
                    configEdit: false,
                },
                currentDate: new Date()
            }
        },
        mounted() {
            this.init()
        },
        methods: {
            init() {
                let that = this;
                if (that.param && that.param.id) {
                    that.$api.company.package.detail(that.param.id).then(response => {
                        let res = response.data;
                        if (res.code == 1) {
                            that.order = res.data;
                        } else {
                            that.$notify({type: 'warning', message: res.msg})
                        }
                    })
                }
            },
            onSubmit() {
                let that = this;
                that.$api.company.package.input(this.order).then(response => {
                    let res = response.data;
                    if (res.code == 1) {
                        // 独立弹出
                        if (that.node && that.node.id) {
                            that.$emit('onClose', that.node);
                        }
                        // 客户查询内弹出
                        if (that.param) {
                            if (that.param.id > 0) {
                                that.$emit('onChange');
                                that.$toast.success('修改成功')
                            } else {
                                that.$emit('onClose');
                                that.$toast.success('新增成功')
                            }
                        }
                    } else {
                        that.$notify({type: 'warning', message: res.msg})
                    }
                })
            },
            onChangeStartDate(value) {
                this.order.master.start = this.dateFormat(value);
                this.picker.start = false;
            },
            onChangeEndDate(value) {
                this.order.master.end = this.dateFormat(value);
                this.picker.end = false;
            },
            dateFormat(value) {
                var date = new Date(value);
                var year = date.getFullYear();
                var month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
                var day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
                return year + "-" + month + "-" + day;
            },
            onChangeType(value) {
                this.order.master.type = value.index;
                this.order.master.typeName = value.text;
                this.picker.type = false;
            },
            onChangeLength(value) {
                this.order.master.length = value.index;
                this.order.master.lengthName = value.text;
                this.picker.length = false;
            },
            onAddItem() {
                this.item = {
                    id: 0,
                    title: '',
                    info: '',
                    detail: '',
                    config: []
                };
                this.picker.itemAdd = true;
            },
            onAddItemComplete() {
                if (!this.item.title)
                    return this.$toast('请输入套餐名称');
                if (!this.item.info)
                    return this.$toast('请输入特别说明');
                this.picker.itemAdd = false;
                this.order.goods.push({
                    id: this.item.id,
                    title: this.item.title,
                    info: this.item.info,
                    detail: this.item.detail,
                    config: []
                });
            },
            onEditItem(index) {
                this.item = this.order.goods[index];
                this.picker.itemEdit = true;
            },
            onEditItemComplete() {
                this.picker.itemEdit = false;
            },
            onDeleteItem(index) {
                this.$dialog.confirm({
                    message: '确认删除吗？'
                }).then(() => {
                    this.order.goods.splice(index, 1);
                });
            },
            onConfigItem(index) {
                this.configList = this.order.goods[index].config;
                this.picker.itemConfig = true;
            },
            onConfigItemComplete() {
                this.picker.itemConfig = false;
            },
            onAddConfig() {
                this.config = {
                    id: 0,
                    card_id: '',
                    num: '',
                    title: '',
                    info: '',
                };
                this.picker.configAdd = true;
            },
            onAddConfigComplete() {
                this.picker.configAdd = false;
                this.configList.push({
                    id: this.config.id,
                    card_id: this.config.card_id,
                    num: this.config.num,
                    title: this.config.title,
                    info: this.config.info,
                });
            },
            onEditConfig(index) {
                this.config = this.configList[index];
                this.picker.configEdit = true;
            },
            onEditConfigComplete() {
                this.picker.configEdit = false;
            },
            onDeleteConfig(index) {
                this.$dialog.confirm({
                    message: '确认删除吗？'
                }).then(() => {
                    this.configList.splice(index, 1);
                });
            }
        },
        watch: {
            'order.master.prefix': function () {
                this.order.master.prefix = this.order.master.prefix.replace(/[^A-Za-z0-9]/g, '')
            },
        }
    }
</script>

<style scoped>

    .customer-package-input-container-form {
        position: fixed;
        width: 100%;
        top: 46px;
        bottom: 50px;
        overflow-y: scroll;
    }

    .van-swipe-cell .van-button {
        height: 100%;
    }

</style>
